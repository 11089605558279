import styles from "./Services.module.css";
import servicesImage from "assets/images/services.jpg";
import serviceDrywall from "assets/images/serviceDrywall.jpg";
import serviceConsult from "assets/images/serviceConsult.jpg";
import servicePaint from "assets/images/servicePaint.jpg";
import serviceCabinet from "assets/images/serviceCabinet.jpg";

const servicesData = [
  {
    id: 1,
    title: "Exterior Painting & Staining",
    description:
      "Transform your exterior with our expert painting and staining services. Enhance your curb appeal today.",
    image: servicesImage,
  },
  {
    id: 2,
    title: "Cabinet Painting",
    description:
      "Revamp your cabinets with our professional painting services. Update your space in no time.",
    image: serviceCabinet,
  },
  {
    id: 3,
    title: "Interior Painting",
    description:
      "Transform your space with our interior painting services. Elevate your home today.",
    image: servicePaint,
  },
  {
    id: 4,
    title: "Drywall",
    description:
      "Create a flawless finish with our expert drywalling services. Perfect for any project.",
    image: serviceDrywall,
  },
  {
    id: 5,
    title: "Color Consultation",
    description:
      "Get expert color advice with our complimentary consultation. Perfect for your next project.",
    image: serviceConsult,
  },
];

const Services = () => {
  return (
    <section id="services" className={styles.service}>
      <div className={styles.serviceHeading}>
        <h2>
          Our <span>Services</span>
        </h2>
      </div>
      <div className={styles.serviceContentContainer}>
        {servicesData.map((service, key) => {
          return (
            <div key={key} className={styles.serviceItem}>
              <div className={styles.serviceImage}>
                <img
                  src={service.image}
                  alt="services section"
                  width="600"
                  height="400"
                />
              </div>
              <div className={styles.serviceContent}>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Services;
