import React, { useState } from "react";
import heroImageDesktop from "assets/images/heroImage.jpg";
import heroImageMobile from "assets/images/heroImageMobile.jpg";
import styles from "./HeroContent.module.css";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#ad3c19",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#ff5722",
    },
  },
});

export const HeroContent = (props) => {
  const [showModal, setShowModal] = useState(false);

  const classes = useStyles();

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleContactClick = () => {
    console.log("Contact Clicked");
    setShowModal(true);
  };

  return (
    <div id="home" className={styles.heroImageContainer}>
      <img
        src={props.isMobile ? heroImageMobile : heroImageDesktop}
        alt="Hero"
        className={styles.heroImage}
      />
      <div className={`${styles.heroText}`}>
        <h1 className={styles.slideInTextHalfSecond}>
          Highest Quality Work At A Great Value
        </h1>
        <p className={styles.slideInTextHalfSecond}>
          Providing the highest quality work at a great value <br />
          is our number one priority
        </p>
        <Button
          aria-label="contact"
          size="small"
          className={styles.slideInTextHalfSecond}
          onClick={handleContactClick}
        >
          Contact Us
        </Button>
      </div>
      {showModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <span className={styles.close} onClick={handleModalClose}>
                &times;
              </span>
              <h2 style={{ color: "#ad3c19" }}>CONTACT US</h2>
              <div className={styles.modalButtons}>
                <Button
                  aria-label="call us"
                  className={classes.button}
                  variant="contained"
                  onClick={() => (window.location.href = "tel:1234567890")}
                >
                  Call Us
                </Button>
                <Button
                  aria-label="email us"
                  className={classes.button}
                  variant="contained"
                  onClick={() =>
                    (window.location.href =
                      "mailto:moorequalityfinishes@gmail.com?subject=Website%20Inquiry")
                  }
                >
                  Email Us
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroContent;
