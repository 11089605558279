import { createClient } from "contentful";
import { useCallback, useMemo } from "react";
import {
  setContentfulProjects,
  setContentfulStaff,
  setSelectedProject,
} from "store/actions/contentfulActions";
import { store } from "store/store";

const useContentful = () => {
  const client = useMemo(
    () =>
      createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        host: "cdn.contentful.com",
      }),
    []
  );

  const getContentfulProjects = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "projects",
        select: "fields",
        order: "fields.name",
      });
      store.dispatch(setContentfulProjects(entries));
    } catch (error) {
      console.log(`Error Fetching Programs:${error}`);
    }
  };

  const getContentfulStaff = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "staff",
        select: "fields",
        order: "fields.staffId",
      });
      store.dispatch(setContentfulStaff(entries));
    } catch (error) {
      console.log(`Error Fetching Programs:${error}`);
    }
  };

  const getContentfulProjectById = useCallback(
    async (projectId) => {
      try {
        const entries = await client.getEntries({
          content_type: "projects",
          "fields.projectId": projectId,
        });
        store.dispatch(setSelectedProject(entries));
      } catch (error) {
        console.log(`Error Fetching Programs:${error}`);
      }
    },
    [client]
  );
  return {
    getContentfulProjects,
    getContentfulStaff,
    getContentfulProjectById,
  };
};

export default useContentful;
