import styles from "./Footer.module.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PhoneIcon from "@material-ui/icons/Phone";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigation = useNavigate();

  function handleLinkClick(event, sectionId) {
    event.preventDefault();
    navigation("/");
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerItem}>
          <a
            href="/"
            className={styles.a}
            onClick={(event) => handleLinkClick(event, "home")}
          >
            <div className={styles.text}>
              <h3>Moore Quality Finishes</h3>
            </div>
          </a>
          <p>
            We are a professional painting company located in Bardstown KY. Our
            team prides ourselves on quality painting with professionalism. We
            offer both residential and commercial painting
          </p>
        </div>
        <div className={styles.footerItem}>
          <div className={styles.logo}>
            <div className={styles.text}>
              <h3>Services</h3>
              <div className={styles.links}>
                <a
                  href="/"
                  className={styles.a}
                  onClick={(event) => handleLinkClick(event, "services")}
                >
                  <ArrowForwardIosIcon
                    style={{ fontSize: "1rem", color: "white" }}
                  />
                  Interior Painting
                </a>
                <a
                  href="/"
                  className={styles.a}
                  onClick={(event) => handleLinkClick(event, "services")}
                >
                  <ArrowForwardIosIcon
                    style={{ fontSize: "1rem", color: "white" }}
                  />
                  Exterior Painting
                </a>
                <a
                  href="/"
                  className={styles.a}
                  onClick={(event) => handleLinkClick(event, "services")}
                >
                  <ArrowForwardIosIcon
                    style={{ fontSize: "1rem", color: "white" }}
                  />
                  Drywall
                </a>
                <a
                  href="/"
                  className={styles.a}
                  onClick={(event) => handleLinkClick(event, "services")}
                >
                  <ArrowForwardIosIcon
                    style={{ fontSize: "1rem", color: "white" }}
                  />
                  Color Consultation
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerItem}>
          <div className={styles.logo}>
            <div className={styles.text}>
              <h3>Quick Links</h3>
              <div className={styles.links}>
                <a
                  href="/"
                  className={styles.a}
                  onClick={(event) => handleLinkClick(event, "home")}
                >
                  <ArrowForwardIosIcon
                    style={{ fontSize: "1rem", color: "white" }}
                  />
                  Home
                </a>
                <a
                  href="/"
                  className={styles.a}
                  onClick={(event) => handleLinkClick(event, "about")}
                >
                  <ArrowForwardIosIcon
                    style={{ fontSize: "1rem", color: "white" }}
                  />
                  About
                </a>
                <a
                  href="/"
                  className={styles.a}
                  onClick={(event) => handleLinkClick(event, "services")}
                >
                  <ArrowForwardIosIcon
                    style={{ fontSize: "1rem", color: "white" }}
                  />
                  Services
                </a>
                <a
                  href="/"
                  className={styles.a}
                  onClick={(event) => handleLinkClick(event, "projects")}
                >
                  <ArrowForwardIosIcon
                    style={{ fontSize: "1rem", color: "white" }}
                  />
                  Projects
                </a>
                <a
                  href="/"
                  className={styles.a}
                  onClick={(event) => handleLinkClick(event, "team")}
                >
                  <ArrowForwardIosIcon
                    style={{ fontSize: "1rem", color: "white" }}
                  />
                  Team
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerItem}>
          <div className={styles.logo}>
            <div className={styles.contactContainer}>
              <h3>Contact Info</h3>
              <div>
                <p className={styles.contact}>
                  <PhoneIcon style={{ fontSize: "1rem", color: "white" }} />
                  <a className={styles.phoneNumber} href={`tel:5025072084`}>
                    502-507-2084
                  </a>
                </p>
                <p className={styles.contact}>
                  <EmailIcon style={{ fontSize: "1rem", color: "white" }} />
                  <a
                    className={styles.email}
                    href={`mailto:moorequalityfinishes@gmail.com?subject=Website%20Inquiry`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Email Us
                  </a>
                </p>
              </div>
              <div className={styles.social}>
                <a
                  href="https://www.facebook.com/moorequalityfinishes/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Moore Quality Finishes Facebook Page"
                >
                  <FacebookIcon style={{ fontSize: "3rem", color: "white" }} />
                </a>
                <a
                  href="https://www.facebook.com/moorequalityfinishes/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Moore Quality Finishes Instagram Page"
                >
                  <InstagramIcon style={{ fontSize: "3rem", color: "white" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerCredit}>
        <p>
          {`Website designed and built by `}
          <a
            href="https://www.bourboncitywebdevelopment.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Bourbon City Web Development website in a new tab"
          >
            Bourbon City Web Development
          </a>
          .
        </p>
      </div>
    </footer>
  );
}

export default Footer;
