import BeforeAfterImages from "components/BeforeAfterImages/BeforeAfterImages";
import ProjectDetailsBody from "components/ProjectDetailsBody/ProjectDetailsBody";
import ProjectDetailsHeader from "components/ProjectDetailsHeader/ProjectDetailsHeader";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useContentful from "services/useContentful";
import styles from "./ProjectDetails.module.css";

const ProjectDetails = () => {
  const contentfulProject = useSelector(
    (state) => state.contentfulReducer.selectedProject
  );
  const [photoGallery, setPhotoGallery] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { projectId } = useParams();

  const { getContentfulProjectById } = useContentful();

  const createPhotoGallery = useCallback(() => {
    const newPhotoGallery = [];
    if (contentfulProject) {
      const beforePhotos = contentfulProject?.fields?.photos?.filter((photo) =>
        photo.fields.title.startsWith("Before")
      );
      for (let i = 0; i < beforePhotos?.length; i++) {
        const beforePhoto = beforePhotos[i];
        const afterPhoto = contentfulProject?.fields?.photos.find(
          (photo) =>
            photo.fields.title === `After${beforePhoto.fields.title.slice(6)}`
        );
        if (afterPhoto) {
          const photoObject = {
            before: beforePhoto.fields,
            after: afterPhoto.fields,
          };
          newPhotoGallery.push(photoObject);
        }
      }
    }
    setPhotoGallery(newPhotoGallery);
  }, [contentfulProject]);

  useEffect(() => {
    createPhotoGallery();
  }, [createPhotoGallery]);

  useEffect(() => {
    const fetch = async () => {
      await getContentfulProjectById(projectId);
      setIsLoading(false);
    };
    fetch();
  }, [getContentfulProjectById, projectId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <section>
      <ProjectDetailsHeader projectName={contentfulProject.fields.name} />
      <ProjectDetailsBody project={contentfulProject} />
      <div className={styles.mediaGalleryContainer}>
        {photoGallery &&
          photoGallery.map((photo, key) => (
            <BeforeAfterImages
              key={key}
              beforeSrc={photo.before.file.url}
              beforeAlt="Before Image"
              afterSrc={photo.after.file.url}
              afterAlt="After Image"
            />
          ))}
      </div>
    </section>
  );
};

export default ProjectDetails;
