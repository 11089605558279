import styles from "App.module.css";
import { useEffect, useState } from "react";
import { NavBar } from "./containers/NavBar/NavBar";
import Home from "containers/Home/Home";
import { Route, Routes } from "react-router-dom";
import Footer from "components/Footer/Footer";
import ProjectDetails from "containers/ProjectDetails/ProjectDetails";
import useContentful from "services/useContentful";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  const { getContentfulProjects, getContentfulStaff } = useContentful();

  useEffect(() => {
    getContentfulProjects();
    getContentfulStaff();
  }, [getContentfulProjects, getContentfulStaff]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Call the handler initially to set the initial state.

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={styles.App}>
      {/* {isMobile && <ContactBar />} */}
      <NavBar isMobile={isMobile} />
      <Routes>
        <Route path="/" element={<Home isMobile={isMobile} />} />
        <Route path="/project/:projectId" element={<ProjectDetails />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
