import React from "react";
import styles from "./ParallaxImage.module.css";
import midPageBreak from "assets/images/midPageBreakDesktop.jpg";
import { Button } from "@material-ui/core";

function ParallaxImage() {
  return (
    <div className={styles.midPageBreakContainer}>
      <div className={styles.midPageBreakContent}>
        <Button
          aria-label="call"
          variant="contained"
          color="primary"
          size="large"
          style={{ color: "var(--primary-color)", backgroundColor: "white" }}
          href="tel:+1234567890"
        >
          Call Us
        </Button>
      </div>
      <div
        className={styles.midPageBreakBackgroundImage}
        style={{ backgroundImage: `url(${midPageBreak})` }}
      ></div>
    </div>
  );
}

export default ParallaxImage;
