import styles from "./ProjectVideoPlayer.module.css";
import { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export const YouTubeVideo = ({ videoId }) => {
  const videoSrc = `https://www.youtube.com/embed/${videoId}?mute=1&loop=1&playlist=${videoId}`;

  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
  };

  return (
    <div className={styles.videoContainer}>
      {loading && (
        <CircularProgress
          className={styles.loadingIndicator}
          style={{ color: "#ad3c19" }}
        />
      )}
      <iframe
        src={videoSrc}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        onLoad={handleLoad}
        onError={handleError}
        style={{ display: loading ? "none" : "flex" }}
      ></iframe>
    </div>
  );
};
