import styles from "./Home.module.css";
import { HeroContent } from "components/HeroContent/HeroContent";
import About from "components/About/About";
import Services from "components/Services/Services";
import ParallaxImage from "components/ParallaxImage/ParallaxImage";
import image from "assets/images/heroImage.jpg";
import Projects from "components/Projects/Projects";
import Staff from "components/Staff/Staff";

const Home = (props) => {
  return (
    <div className={styles.home}>
      <HeroContent isMobile={props.isMobile} />
      <About />
      <ParallaxImage imageUrl={image} />
      <Services />
      <Projects />
      <Staff />
    </div>
  );
};

export default Home;
