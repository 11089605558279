import styles from "./ProjectDetailsHeader.module.css";

const ProjectDetailsHeader = (props) => {
  return (
    <div className={styles.projectDetailsHeader}>
      <span className={styles.projectHeroOverlay} />
      <div className={styles.projectHeaderTitle}>
        <h1 className={styles.slideInTextHalfSecond}>
          {props.projectName.toUpperCase()}
        </h1>
      </div>
      <div className={styles.thinLineEffect}>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default ProjectDetailsHeader;
