import { YouTubeVideo } from "components/ProjectVideoPlayer/ProjectVideoPlayer";
import styles from "./ProjectMedia.module.css";

const ProjectMedia = (props) => {
  return (
    <div className={styles.projectMedia}>
      <YouTubeVideo videoId={props.project.fields.youtubeLink} />
    </div>
  );
};

export default ProjectMedia;
