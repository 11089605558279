import projectImage from "assets/images/projectImage.png";
import staffImage from "assets/images/staffImage.png";

export const buttonFilterData = [
  {
    id: "all",
    label: "All",
  },
  {
    id: "interior",
    label: "Interior",
  },
  {
    id: "exterior",
    label: "Exterior",
  },
  {
    id: "drywall",
    label: "Drywall",
  },
  {
    id: "cabinet",
    label: "Cabinet",
  },
];

export const mockProjectsData = [
  {
    id: 1,
    title: "Project 1",
    description:
      "We are a professional painting company located in Bardstown KY. Our team prides ourselves on quality painting with professionalism. We offer both residential and commercial painting",
    image: projectImage,
    projectType: ["interior", "exterior"],
  },
  {
    id: 2,
    title: "Project 2",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nisl eget ultricies lacinia, nisl nunc aliquam mauris, vitae ultri",
    image: projectImage,
    projectType: ["interior", "drywall"],
  },
  {
    id: 3,
    title: "Project 3",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nisl eget ultricies lacinia, nisl nunc aliquam mauris, vitae ultri",
    image: projectImage,
    projectType: ["exterior", "drywall"],
  },
  {
    id: 4,
    title: "Project 4",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nisl eget ultricies lacinia, nisl nunc aliquam mauris, vitae ultri",
    image: projectImage,
    projectType: ["interior", "exterior"],
  },
  {
    id: 5,
    title: "Project 5",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nisl eget ultricies lacinia, nisl nunc aliquam mauris, vitae ultri",
    image: projectImage,
    projectType: ["interior", "drywall"],
  },
];

export const mockStaffData = [
  {
    id: 1,
    name: "Jay Moore",
    position: "Owner",
    image: staffImage,
  },
  {
    id: 2,
    name: "Brent Smith",
    position: "Painter",
    image: staffImage,
  },
  {
    id: 3,
    name: "Bradley Cooper",
    position: "Junior Painter",
    image: staffImage,
  },
  {
    id: 4,
    name: "Bradley Cooper",
    position: "Junior Painter",
    image: staffImage,
  },
];
