import {
  SET_CONTENTFUL_PROJECTS,
  SET_SELECTED_PROJECT,
  SET_CONTENTFUL_STAFF,
} from "../actions/contentfulActions";

const initialState = {
  projects: [],
  staff: [],
  selectedProject: [],
};

const contentfulReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTENTFUL_PROJECTS:
      return { ...state, projects: action.payload.items };
    case SET_SELECTED_PROJECT:
      return { ...state, selectedProject: action.payload.items[0] };
    case SET_CONTENTFUL_STAFF:
      return { ...state, staff: action.payload.items };
    default:
      return state;
  }
};

export default contentfulReducer;
