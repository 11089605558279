export const SET_CONTENTFUL_PROJECTS = "SET_CONTENTFUL_PROJECTS";

export function setContentfulProjects(response) {
  return {
    type: SET_CONTENTFUL_PROJECTS,
    payload: response,
  };
}

export const SET_CONTENTFUL_STAFF = "SET_CONTENTFUL_STAFF";

export function setContentfulStaff(response) {
  return {
    type: SET_CONTENTFUL_STAFF,
    payload: response,
  };
}

export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";

export const setSelectedProject = (response) => ({
  type: SET_SELECTED_PROJECT,
  payload: response,
});
