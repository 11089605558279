import styles from "./About.module.css";
import FormatPaintIcon from "@material-ui/icons/FormatPaint";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import aboutImage from "assets/images/aboutImage.jpg";

const aboutInfoData = [
  {
    icon: <FormatPaintIcon style={{ color: "var(--primary-color)" }} />,
    title: "Reliable Services",
  },
  {
    icon: <AttachMoneyIcon style={{ color: "var(--primary-color)" }} />,
    title: "Affordable Prices",
  },
  {
    icon: <EmojiPeopleIcon style={{ color: "var(--primary-color)" }} />,
    title: "Expert Painters",
  },
  {
    icon: <HourglassEmptyIcon style={{ color: "var(--primary-color)" }} />,
    title: "Timely Service",
  },
];

const About = () => {
  return (
    <section id="about" className={styles.about}>
      <div className={styles.aboutHeading}>
        <h2>
          <span>About</span> Us
        </h2>
      </div>
      <div className={styles.aboutContentContainer}>
        <div className={styles.aboutContent}>
          <h3>We Provide Experienced And Quality Services At Your Doorstep</h3>
          <p>
            We are a professional painting company located in Bardstown KY. Our
            team prides ourselves on quality painting with professionalism. We
            offer both residential and commercial painting
          </p>
          <div className={styles.aboutInfo}>
            {aboutInfoData.map((info, key) => {
              return (
                <h4 key={key}>
                  {info.icon}
                  <span>{info.title}</span>
                </h4>
              );
            })}
          </div>
        </div>
        <div className={styles.aboutImage}>
          <img
            src={aboutImage}
            alt="about section"
            width="1000"
            height="1000"
          />
        </div>
      </div>
    </section>
  );
};

export default About;
