import styles from "./ProjectInfo.module.css";

const ProjectInfo = (props) => {
  return (
    <div className={styles.projectInfo}>
      <div className={styles.mainInfo}>
        <h2>Project Information</h2>
        <p>{props.project.fields.projectDescription}</p>
      </div>
      <div className={styles.projectMeta}>
        <div className={styles.row}>
          <div className={styles.col}>CLIENT</div>
          <div className={`${styles.col} ${styles.colMedium}`}>
            {props.project.fields.client}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>Year</div>
          <div className={`${styles.col} ${styles.colMedium}`}>
            {props.project.fields.year}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>Category</div>
          <div className={`${styles.col} ${styles.colMedium}`}>
            {props.project.fields.progressPictures.map(
              (progressPicture, key) => {
                return <span key={key}>{progressPicture}</span>;
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;
