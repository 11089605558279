import styles from "./BeforeAfterImages.module.css";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const BeforeAfterImages = ({ beforeSrc, beforeAlt, afterSrc, afterAlt }) => {
  const [lightbox, setLightbox] = useState({ isOpen: false, currentImage: "" });

  const openLightbox = (src) => {
    setLightbox({ isOpen: true, currentImage: src });
  };

  const closeLightbox = () => {
    setLightbox({ isOpen: false, currentImage: "" });
  };

  return (
    <div className={styles.imagesContainer}>
      <div
        className={styles.imageContainer}
        onClick={() => openLightbox(beforeSrc)}
      >
        <img src={beforeSrc} alt={beforeAlt} />
        <span className={styles.imageLabel}>Before</span>
      </div>
      <div
        className={styles.imageContainer}
        onClick={() => openLightbox(afterSrc)}
      >
        <img src={afterSrc} alt={afterAlt} />
        <span className={styles.imageLabel}>After</span>
      </div>
      {lightbox.isOpen && (
        <Lightbox
          mainSrc={lightbox.currentImage}
          onCloseRequest={closeLightbox}
          imageTitle={lightbox.currentImage === beforeSrc ? "Before" : "After"}
        />
      )}
    </div>
  );
};

export default BeforeAfterImages;
