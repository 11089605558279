import { useEffect, useState } from "react";
import logo from "assets/images/MooreQualityFinishesLogo.png";
import styles from "./NavBarMobile.module.css";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  outlinedButton: {
    borderColor: "var(--primary-color)",
    color: "white",
    borderRadius: "50px",
    background:
      "linear-gradient(to right, var(--primary-color) 50%, white 50%)",
    backgroundSize: "200% 100%",
    transition: "background-position 0.5s",
    "&:hover": {
      borderColor: "var(--primary-color)",
      color: "var(--primary-color)",
      backgroundPosition: "100.5% 0",
    },
  },
  menuButton: {
    transition: "transform 0.5s ease-out",
    "&.open": {
      //   transform: "rotate(90deg)",
    },
  },
  disappear: {
    animation: "$disappear 0.3s ease forwards",
  },
  appear: {
    animation: "$appear 0.3s ease forwards",
  },
  "@keyframes disappear": {
    "0%": {
      opacity: 1,
      transform: "translateX(0)",
    },
    "100%": {
      opacity: 0,
      transform: "translateX(-100%)",
    },
  },
  "@keyframes appear": {
    "0%": {
      opacity: 0,
      transform: "translateX(100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
}));

export const NavBarMobile = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const items = [
    { id: "home", label: "Home" },
    { id: "about", label: "About" },
    { id: "services", label: "Services" },
    { id: "projects", label: "Projects" },
    { id: "team", label: "Team" },
  ];
  const itemsWhenNotOnHome = [{ id: "home", label: "Home" }];
  const handleSetActive = (to) => {
    const index = items.findIndex((item) => item.id === to);
    setActiveItemIndex(index);
  };

  const scrollToTopHome = (index) => {
    setActiveItemIndex(index);
    window.scrollTo(0, 0);
  };

  const classes = useStyles();

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    function handleScroll() {
      setShowMenu(false);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setShowMenu(false);
  }, [activeItemIndex]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        zIndex: 1100,
      }}
    >
      <div
        className={styles.navBarMobile}
        style={{
          height: `${scrolled ? "100px" : "125px"}`,
          transition: "height .5s",
        }}
      >
        <div className={styles.navMenu}>
          <RouterLink to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={logo} alt="logo" width="75" height="75" />
          </RouterLink>
          <IconButton
            aria-label="navigation menu open"
            className={`${classes.menuButton} ${showMenu ? "open" : ""}`}
            onClick={handleMenuClick}
          >
            {showMenu ? (
              <CloseIcon
                aria-label="navigation menu open"
                className={`${styles.menuIcon} ${
                  showMenu ? classes.appear : classes.disappear
                }`}
                style={{ fontSize: 38, color: "var(--primary-color)" }}
              />
            ) : (
              <MenuIcon
                className={`${styles.menuIcon} ${
                  showMenu ? classes.disappear : classes.appear
                }`}
                style={{ fontSize: 38, color: "var(--primary-color)" }}
              />
            )}
          </IconButton>
        </div>
      </div>
      <div
        className={`${styles.navMenuList} ${
          showMenu ? styles.open : styles.closed
        }`}
      >
        <nav>
          {location.pathname === "/"
            ? items.map((item, index) => (
                <Link
                  href={`#${item.id}`}
                  key={index}
                  activeClass="active"
                  className={`${styles.link} ${
                    index === activeItemIndex ? styles.active : ""
                  }`}
                  to={item.id}
                  spy={true}
                  smooth={true}
                  offset={-200}
                  duration={500}
                  onClick={() => setActiveItemIndex(index)}
                  onSetActive={handleSetActive}
                >
                  <span
                    style={{ textDecoration: "none" }}
                    className={`${styles.link} ${
                      index === activeItemIndex ? styles.active : ""
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
              ))
            : itemsWhenNotOnHome.map((item, index) => (
                <RouterLink
                  key={index}
                  style={{ textDecoration: "none" }}
                  activeClass="active"
                  className={`${styles.link} ${styles.active}`}
                  to={`/`}
                  onClick={() => scrollToTopHome(index)}
                >
                  {item.label}
                </RouterLink>
              ))}
        </nav>
      </div>
    </div>
  );
};
