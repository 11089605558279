import ProjectInfo from "components/ProjectInfo/ProjectInfo";
import ProjectMedia from "components/ProjectMedia/ProjectMedia";
import styles from "./ProjectDetailsBody.module.css";

const ProjectDetailsBody = (props) => {
  return (
    <div className={styles.projectDetailsBody}>
      <ProjectInfo project={props.project} />
      <ProjectMedia project={props.project} />
    </div>
  );
};

export default ProjectDetailsBody;
