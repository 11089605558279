import styles from "./Staff.module.css";
import { useSelector } from "react-redux";

const Staff = () => {
  let contentfulStaff = useSelector((state) => state.contentfulReducer.staff);
  return (
    <section id="team" className={styles.staff}>
      <div className={styles.staffHeading}>
        <h2>
          Our <span>Team</span>
        </h2>
      </div>
      <div className={styles.staffContentContainer}>
        {contentfulStaff.map((staff, key) => {
          return (
            <div key={key} className={styles.staffItem}>
              <div className={styles.staffImage}>
                <img
                  src={staff.fields.photo.fields.file.url}
                  alt="team section"
                  width="400"
                  height="400"
                />
              </div>
              <div className={styles.staffContent}>
                <h3>{staff.fields.name}</h3>
                <p>{staff.fields.position}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Staff;
