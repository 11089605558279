import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import logo from "assets/images/MooreQualityFinishesLogo.png";
import styles from "./NavBarDesktop.module.css";
import { Button, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  outlinedButton: {
    borderColor: "var(--primary-color)",
    color: "white",
    borderRadius: "50px",
    background:
      "linear-gradient(to right, var(--primary-color) 50%, white 50%)",
    backgroundSize: "200% 100%",
    transition: "background-position 0.5s",
    "&:hover": {
      borderColor: "var(--primary-color)",
      color: "var(--primary-color)",
      backgroundPosition: "100.5% 0",
    },
  },
}));

export const NavBarDesktop = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const items = [
    { id: "home", label: "Home" },
    { id: "about", label: "About" },
    { id: "services", label: "Services" },
    { id: "projects", label: "Projects" },
    { id: "team", label: "Team" },
  ];
  const itemsWhenNotOnHome = [{ id: "home", label: "Home" }];
  const handleSetActive = (to) => {
    const index = items.findIndex((item) => item.id === to);
    setActiveItemIndex(index);
  };
  const classes = useStyles();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <div
      className={`${styles.navBarDesktop}`}
      style={{
        height: `${scrolled ? "100px" : "125px"}`,
        transition: "height .5s",
      }}
    >
      <RouterLink to="/">
        <img src={logo} alt="logo" width="100" height="100" />
      </RouterLink>
      <div className={styles.navBarLinkContainer}>
        <ul className={styles.navMenuList}>
          {location.pathname === "/"
            ? items.map((item, index) => (
                <Link
                  href={`#${item.id}`}
                  key={index}
                  activeClassName="active"
                  className={`${styles.link} ${
                    index === activeItemIndex ? styles.active : ""
                  }`}
                  to={item.id}
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  onSetActive={handleSetActive}
                >
                  {item.label}
                </Link>
                // eslint-disable-next-line
              ))
            : itemsWhenNotOnHome.map((item, index) => (
                <RouterLink
                  key={index}
                  style={{ textDecoration: "none" }}
                  activeClassName="active"
                  className={`${styles.link} ${styles.active}`}
                  to={`/`}
                >
                  {item.label}
                </RouterLink>
              ))}
        </ul>
        <a
          href="mailto:moorequalityfinishes@gmail.com?subject=Website%20Inquiry"
          style={{ textDecoration: "none" }}
        >
          <Button
            aria-label="Get Quote"
            variant="outlined"
            color="primary"
            classes={{ outlined: classes.outlinedButton }}
          >
            Get Quote
          </Button>
        </a>
      </div>
    </div>
  );
};
