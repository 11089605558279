import { useState } from "react";
import styles from "./Projects.module.css";
import { buttonFilterData } from "data";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Projects = () => {
  const [activeButton, setActiveButton] = useState("all");
  let contentfulProjects = useSelector(
    (state) => state.contentfulReducer.projects
  );

  const navigate = useNavigate();

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleProjectClick = async (project) => {
    await window.scrollTo(0, 0);

    navigate(`/project/${project.fields.projectId}`);
  };

  return (
    <section id="projects" className={styles.project}>
      <div className={styles.projectHeading}>
        <h2>
          Our <span>Projects</span>
        </h2>
      </div>
      <div className={styles.projectContentContainer}>
        <ul className={styles.controls}>
          {buttonFilterData.map((button) => {
            return (
              <li
                key={button.id}
                id={activeButton === button.id ? "active" : ""}
                className={`${styles.button} ${styles.btn} ${
                  activeButton === button.id ? styles.active : ""
                }`}
                onClick={() => handleButtonClick(button.id)}
              >
                {button.label}
              </li>
            );
          })}
        </ul>
        <div className={styles.projectsList}>
          {contentfulProjects.map((project, key) => {
            if (
              activeButton === "all" ||
              project.fields.progressPictures.includes(activeButton)
            ) {
              return (
                <div
                  key={key}
                  className={styles.projectItem}
                  onClick={() => handleProjectClick(project)}
                >
                  <img
                    src={project.fields.homepageImage.fields.file.url}
                    className={styles.zoomableImage}
                    alt="projects"
                  />
                  <span className={styles.projectName}>
                    {project.fields.name}
                  </span>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </section>
  );
};

export default Projects;
